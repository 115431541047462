<template>
    <Exception type="404" exceptionStyle="min-height: 500px; height: 80%;" />
</template>

<script>
import Exception from "@/components/Exception"
export default {
  name: "Error404",
  components: {
    Exception
  }
}
</script>
