import { render, staticRenderFns } from "./Exception.vue?vue&type=template&id=726c8806&scoped=true&"
import script from "./Exception.vue?vue&type=script&lang=js&"
export * from "./Exception.vue?vue&type=script&lang=js&"
import style0 from "./Exception.vue?vue&type=style&index=0&id=726c8806&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "726c8806",
  null
  
)

export default component.exports